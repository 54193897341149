import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The AWSDate scalar type represents a valid extended ISO 8601 Date string. In other words, this scalar type accepts date strings of the form YYYY-MM-DD. This scalar type can also accept time zone offsets. For example, 1970-01-01Z, 1970-01-01-07:00 and 1970-01-01+05:30 are all valid dates. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: { input: string; output: string };
  /** The AWSDateTime scalar type represents a valid extended ISO 8601 DateTime string. In other words, this scalar type accepts datetime strings of the form YYYY-MM-DDThh:mm:ss.sssZ. The field after the seconds field is a nanoseconds field. It can accept between 1 and 9 digits. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used). The time zone offset is compulsory for this scalar. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: { input: string; output: string };
  /** The AWSEmail scalar type represents an Email address string that complies with RFC 822. For example, username@example.com is a valid Email address. */
  AWSEmail: { input: string; output: string };
  /** The AWSIPAddress scalar type represents a valid IPv4 or IPv6 address string. */
  AWSIPAddress: { input: string; output: string };
  /**
   * The AWSJSON scalar type represents a JSON string that complies with RFC 8259.
   *
   * Maps like {\"upvotes\": 10}, lists like [1,2,3], and scalar values like \"AWSJSON example string\", 1, and true are accepted as valid JSON. They will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings. Invalid JSON strings like {a: 1}, {'a': 1} and Unquoted string will throw GraphQL validation errors.
   */
  AWSJSON: { input: string; output: string };
  /** The AWSPhone scalar type represents a valid Phone Number. Phone numbers are serialized and deserialized as Strings. Phone numbers provided may be whitespace delimited or hyphenated. The number can specify a country code at the beginning but this is not required. */
  AWSPhone: { input: string; output: string };
  /**
   * The AWSTime scalar type represents a valid extended ISO 8601 Time string. In other words, this scalar type accepts time strings of the form hh:mm:ss.sss. The field after the seconds field is a nanoseconds field. It can accept between 1 and 9 digits. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used). This scalar type can also accept time zone offsets.
   *
   * For example, 12:30Z, 12:30:24-07:00 and 12:30:24.500+05:30 are all valid time strings.
   *
   * The time zone offset must either be Z (representing the UTC time zone) or be in the format hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard.
   */
  AWSTime: { input: string; output: string };
  /** The AWSTimestamp scalar type represents the number of seconds that have elapsed since 1970-01-01T00:00Z. Timestamps are serialized and deserialized as numbers. Negative values are also accepted and these represent the number of seconds till 1970-01-01T00:00Z. */
  AWSTimestamp: { input: string; output: string };
  /** The AWSURL scalar type represents a valid URL string. The URL may use any scheme and may also be a local URL (Ex: <http://localhost/>). URLs without schemes are considered invalid. URLs which contain double slashes are also considered invalid. */
  AWSURL: { input: string; output: string };
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AddImageWithLocationInput = {
  visitId?: InputMaybe<Scalars['ID']['input']>;
  requestId?: InputMaybe<Scalars['ID']['input']>;
  key: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
};

export type AddImageWithLocationPayload = {
  __typename?: 'AddImageWithLocationPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  request?: Maybe<Request>;
  visit?: Maybe<Visit>;
};

export type AddPushTokenInput = {
  token: Scalars['String']['input'];
  platform: PushPlatform;
};

export type AddPushTokenPayload = {
  __typename?: 'AddPushTokenPayload';
  operator?: Maybe<Operator>;
};

export type AddRequestNoteInput = {
  requestId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
  target: Scalars['String']['input'];
};

export type AddVisitNoteInput = {
  visitId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
  target: Scalars['String']['input'];
};

export type Address = {
  __typename?: 'Address';
  street?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['String']['output']>;
  lng?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  street?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  postal?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
};

export type AssignVisitInput = {
  visitId?: InputMaybe<Scalars['ID']['input']>;
  requestId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
  category?: InputMaybe<Scalars['String']['input']>;
  dueZ: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  minutes?: InputMaybe<Scalars['Float']['input']>;
  request?: InputMaybe<RequestInput>;
};

export type Bill = {
  __typename?: 'Bill';
  /** The date of the bill */
  date: Scalars['AWSDate']['output'];
  /** The total amount, including taxes */
  totalAmount: Scalars['Float']['output'];
  /** The due date of the bill */
  due?: Maybe<Scalars['AWSDate']['output']>;
  /** The the portion of the `totalAmount` that is allocatable to GST/HST */
  gstAmount?: Maybe<Scalars['Float']['output']>;
  /** The document reference for this bill (e.g. invoice number) */
  ref?: Maybe<Scalars['String']['output']>;
  /** The location of the bill's supporting document on S3 */
  key?: Maybe<Scalars['String']['output']>;
};

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  /** The system-assigned ID for the checklist item */
  id: Scalars['ID']['output'];
  /** The label to display for this checklist item */
  name: Scalars['String']['output'];
  /** The UTC date/time when the checklist item was completed */
  completedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  /** The order of the checklist item */
  order?: Maybe<Scalars['Int']['output']>;
  /** The last updated date */
  lastUpdatedZ?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type ChecklistItemInput = {
  id: Scalars['ID']['input'];
  completedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type CompleteRequestInput = {
  completedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompleteVisitInput = {
  visitId: Scalars['ID']['input'];
};

export type ContactInformation = {
  __typename?: 'ContactInformation';
  propertyName?: Maybe<Scalars['String']['output']>;
  unitName?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Address>;
  displayAddress?: Maybe<Scalars['String']['output']>;
};

export type DeclineVisitInput = {
  visitId: Scalars['ID']['input'];
};

export type DeleteGcalPayload = {
  __typename?: 'DeleteGcalPayload';
  operator?: Maybe<Operator>;
};

export type DeletePushTokenPayload = {
  __typename?: 'DeletePushTokenPayload';
  operator?: Maybe<Operator>;
};

export type DeleteRequestNoteInput = {
  requestId: Scalars['ID']['input'];
  noteId: Scalars['ID']['input'];
};

export type DeleteVisitNoteInput = {
  visitId: Scalars['ID']['input'];
  noteId: Scalars['ID']['input'];
};

export type Field = {
  __typename?: 'Field';
  /** The label to display for this field */
  label?: Maybe<Scalars['String']['output']>;
  /** The value for this field */
  value?: Maybe<Scalars['String']['output']>;
};

export type FieldInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GcalTokens = {
  __typename?: 'GcalTokens';
  id_token?: Maybe<Scalars['String']['output']>;
  access_token?: Maybe<Scalars['String']['output']>;
  refresh_token?: Maybe<Scalars['String']['output']>;
  token_type?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
};

export type Hint = {
  __typename?: 'Hint';
  /** The hint description label to display */
  label?: Maybe<Scalars['String']['output']>;
  /** Whether the tenant has confirmed the hint */
  value?: Maybe<Scalars['Boolean']['output']>;
  /** Additional help information for this hint */
  info?: Maybe<Scalars['String']['output']>;
};

export type IamManager = {
  __typename?: 'IamManager';
  operator?: Maybe<Operator>;
};

export type IamManagerOperatorArgs = {
  id: Scalars['ID']['input'];
};

export type Image = {
  __typename?: 'Image';
  /**
   * the key will be a subkey in the shape: <domain>/<filename>
   * e.g. tenant/filename.jpg
   * in the images bucket in S3 the files have the following shape:
   * <entity>/<UUID>/<domain>/<filename>
   * e.g. requests/<UUID>/tenant/filename.jpg
   */
  key?: Maybe<Scalars['String']['output']>;
  /** A short description related to the given image */
  notes?: Maybe<Array<Maybe<Note>>>;
};

export type ImageInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
};

export type ManagerContactInformation = {
  __typename?: 'ManagerContactInformation';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  email: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type ModifyRequestNotesPayload = {
  __typename?: 'ModifyRequestNotesPayload';
  request?: Maybe<Request>;
  success?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Updates a request with the inputs provided */
  updateRequest?: Maybe<Request>;
  /**
   * Adds a photo to a request
   * @deprecated Use `addImageWithLocation`.
   */
  addImageKeyToRequest?: Maybe<Request>;
  /** Adds a photo with location information */
  addImageWithLocation: AddImageWithLocationPayload;
  /** Starts a request, updating its status to STARTED */
  startRequest?: Maybe<Request>;
  /**
   * Completes a request, updating its status to COMPLETED
   * @deprecated No request is being stored on operator anymore
   */
  completeRequest?: Maybe<Request>;
  /** Sets the Expo push token for the operator's current device */
  setExpoPushToken?: Maybe<SetExpoPushTokenPayload>;
  /** Adds the push token for the operator's current device */
  addPushToken?: Maybe<AddPushTokenPayload>;
  /** Clears the push tokens for the operator */
  deletePushToken?: Maybe<DeletePushTokenPayload>;
  /** Clears the push tokens for the operator */
  updateUnreadPushNotifications?: Maybe<UpdateUnreadPushNotificationsPayload>;
  /** Sets locale for the operator */
  setLocale?: Maybe<SetLocalePayload>;
  /** Sets the the gcal integration data for this Operator */
  setGcal?: Maybe<SetGcalPayload>;
  /** Deletes the gcal integration for this Operator */
  deleteGcal?: Maybe<DeleteGcalPayload>;
  /** Adds a new operator note into the list of notes of a request */
  addRequestNote?: Maybe<ModifyRequestNotesPayload>;
  /** Deletes an operator note from the list of notes of a request */
  deleteRequestNote?: Maybe<ModifyRequestNotesPayload>;
  /** Sets the emailCalendarOptOut field for the operator */
  setEmailCalendarOptOut?: Maybe<SetEmailCalendarOptOutPayload>;
  /** Broadcasts an event when another visit is required for a request */
  visitRequired?: Maybe<VisitRequiredPayload>;
  /** Starts a visit, updating its status to STARTED */
  startVisit?: Maybe<VisitUpdatedPayload>;
  /** Completes a visit, updating its status to COMPLETED */
  completeVisit?: Maybe<VisitUpdatedPayload>;
  /** Updates a visit costs */
  updateVisitCosts?: Maybe<VisitUpdatedPayload>;
  /**
   * Updates a visit checklist
   * @deprecated Use updateVisitChecklistItem instead
   */
  updateVisitChecklist?: Maybe<VisitUpdatedPayload>;
  updateVisitChecklistItem?: Maybe<VisitUpdatedPayload>;
  /** Declines a visit, updating its status to DECLINED */
  declineVisit?: Maybe<VisitUpdatedPayload>;
  /** Adds a new operator note into the list of notes of a visit */
  addVisitNote?: Maybe<VisitUpdatedPayload>;
  /** Deletes an operator note from the list of notes of a visit */
  deleteVisitNote?: Maybe<VisitUpdatedPayload>;
  /** Deletes an operator note from the list of notes of a visit */
  setVisitSeen?: Maybe<VisitUpdatedPayload>;
  /** Schedules new visit */
  scheduleVisit?: Maybe<VisitUpdatedPayload>;
  /** Assigns new visit */
  assignVisit?: Maybe<VisitUpdatedPayload>;
  /** update assigned visit to scheduled visit */
  scheduleAssignedVisit?: Maybe<VisitUpdatedPayload>;
  /** Sends a login link to the specified e-mail */
  sendLoginLink?: Maybe<SendLoginLinkPayload>;
  /** Update visit bill */
  updateVisitBill?: Maybe<VisitUpdatedPayload>;
};

export type MutationUpdateRequestArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRequestInput;
};

export type MutationAddImageKeyToRequestArgs = {
  id: Scalars['ID']['input'];
  key: Scalars['String']['input'];
};

export type MutationAddImageWithLocationArgs = {
  input: AddImageWithLocationInput;
};

export type MutationStartRequestArgs = {
  id: Scalars['ID']['input'];
  input: StartRequestInput;
};

export type MutationCompleteRequestArgs = {
  id: Scalars['ID']['input'];
  input: CompleteRequestInput;
};

export type MutationSetExpoPushTokenArgs = {
  input: SetExpoPushTokenInput;
};

export type MutationAddPushTokenArgs = {
  input: AddPushTokenInput;
};

export type MutationUpdateUnreadPushNotificationsArgs = {
  input?: InputMaybe<UpdateUnreadPushNotificationsInput>;
};

export type MutationSetLocaleArgs = {
  input: SetLocaleInput;
};

export type MutationSetGcalArgs = {
  input: SetGcalInput;
};

export type MutationAddRequestNoteArgs = {
  input: AddRequestNoteInput;
};

export type MutationDeleteRequestNoteArgs = {
  input: DeleteRequestNoteInput;
};

export type MutationSetEmailCalendarOptOutArgs = {
  input: SetEmailCalendarOptOutInput;
};

export type MutationVisitRequiredArgs = {
  input: VisitRequiredInput;
};

export type MutationStartVisitArgs = {
  input: StartVisitInput;
};

export type MutationCompleteVisitArgs = {
  input: CompleteVisitInput;
};

export type MutationUpdateVisitCostsArgs = {
  input: UpdateVisitCostsInput;
};

export type MutationUpdateVisitChecklistArgs = {
  input: UpdateVisitChecklistInput;
};

export type MutationUpdateVisitChecklistItemArgs = {
  input: UpdateVisitChecklistItemInput;
};

export type MutationDeclineVisitArgs = {
  input: DeclineVisitInput;
};

export type MutationAddVisitNoteArgs = {
  input: AddVisitNoteInput;
};

export type MutationDeleteVisitNoteArgs = {
  input: DeleteVisitNoteInput;
};

export type MutationSetVisitSeenArgs = {
  input: SetVisitSeenInput;
};

export type MutationScheduleVisitArgs = {
  input: ScheduleVisitInput;
};

export type MutationAssignVisitArgs = {
  input: AssignVisitInput;
};

export type MutationScheduleAssignedVisitArgs = {
  input: ScheduleAssignedVisitInput;
};

export type MutationSendLoginLinkArgs = {
  input: SendLoginLinkInput;
};

export type MutationUpdateVisitBillArgs = {
  input: UpdateVisitBillInput;
};

export type Note = {
  __typename?: 'Note';
  /** The system-assigned ID for the note */
  id: Scalars['ID']['output'];
  /** The name of the user who created the note (at the time it was created) */
  createdName?: Maybe<Scalars['String']['output']>;
  /** The uuid identifying the user who created the note (or `system`) */
  createdSub?: Maybe<Scalars['String']['output']>;
  /** The UTC date/time when the note was created */
  createdZ?: Maybe<Scalars['AWSDateTime']['output']>;
  /** The domain this note was created on */
  source?: Maybe<Scalars['String']['output']>;
  /** The domain this note is intended for */
  target?: Maybe<Scalars['String']['output']>;
  /** The contents of the note */
  text?: Maybe<Scalars['String']['output']>;
};

export type NoteInput = {
  id: Scalars['ID']['input'];
  createdName?: InputMaybe<Scalars['String']['input']>;
  createdSub?: InputMaybe<Scalars['String']['input']>;
  createdZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  target?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type Operator = {
  __typename?: 'Operator';
  /** The system-assigned ID for the operator */
  id: Scalars['ID']['output'];
  /** The operator's display name */
  name: Scalars['String']['output'];
  /** The operator's email */
  email: Scalars['AWSEmail']['output'];
  /** The operator's phone */
  phone?: Maybe<Scalars['String']['output']>;
  /** The geographical location of this operator */
  location?: Maybe<Scalars['String']['output']>;
  /** The account ID associated with this operator */
  accountId: Scalars['ID']['output'];
  /** A list of requests associated with this operator */
  requests: Array<Maybe<Request>>;
  /** A list of visits associated with this operator */
  visits?: Maybe<Array<Maybe<Visit>>>;
  /** A paginated list of visits associated with this operator */
  listVisits: VisitConnection;
  /** The Expo push token for this operator's device */
  expoPushToken?: Maybe<Scalars['String']['output']>;
  /** If set, equals the id of the operator and indicates a Google calendar is linked */
  gcalId?: Maybe<Scalars['String']['output']>;
  /** The client secret used for Google auth */
  gcalClientSecret: Scalars['String']['output'];
  /** The gcal access token data */
  gcalTokens?: Maybe<GcalTokens>;
  emailCalendarOptOut?: Maybe<Scalars['Boolean']['output']>;
  /** whether the operator is on staff or adHoc */
  operatorType?: Maybe<OperatorType>;
  /** whether the operator has any non finalized visits */
  hasNonFinalizedVisits?: Maybe<Scalars['Boolean']['output']>;
  /** The push token config for this operator's device */
  pushTokens?: Maybe<Array<PushToken>>;
  /** The locale preference for this operator */
  locale?: Maybe<Scalars['String']['output']>;
  /** The unread push notifications for this operator */
  unreadPushNotifications?: Maybe<Scalars['Int']['output']>;
};

export type OperatorListVisitsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export enum OperatorType {
  AdHoc = 'adHoc',
  Staff = 'staff',
}

export type Property = {
  __typename?: 'Property';
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Address>;
  account?: Maybe<PropertyAccount>;
};

export type PropertyAccount = {
  __typename?: 'PropertyAccount';
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type PropertyAccountInput = {
  phone?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PropertyInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<AddressInput>;
  account?: InputMaybe<PropertyAccountInput>;
};

export enum PushPlatform {
  Adm = 'ADM',
  Apns = 'APNS',
  ApnsSandbox = 'APNS_SANDBOX',
  Gcm = 'GCM',
}

export type PushToken = {
  __typename?: 'PushToken';
  /** The system-assigned ID for the push token */
  id: Scalars['ID']['output'];
  /** The push token for this operator's device */
  token?: Maybe<Scalars['String']['output']>;
  /** The platform of the device */
  platform?: Maybe<PushPlatform>;
};

export type Query = {
  __typename?: 'Query';
  /** Gets the operator specified by the ID (access permitting) or the currently authenticated operator */
  operator?: Maybe<Operator>;
  /** Gets a specific request by ID */
  request?: Maybe<Request>;
  /** Gets a specific visit by ID */
  visit?: Maybe<Visit>;
  /** Gets the currently configured account-level phone number and name for the currently authenticated operator */
  account?: Maybe<Account>;
  /** Queries for manager domain */
  iamManager?: Maybe<IamManager>;
};

export type QueryOperatorArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryRequestArgs = {
  id: Scalars['ID']['input'];
};

export type QueryVisitArgs = {
  id: Scalars['ID']['input'];
};

export type Request = {
  __typename?: 'Request';
  /** The system-assigned ID for the request */
  id: Scalars['ID']['output'];
  /** The unit this request is about */
  unit: Unit;
  /** The request code used for quick identification */
  name: Scalars['String']['output'];
  /** The short summary of the request */
  summary: Scalars['String']['output'];
  /** The original message text that kicked off the request */
  originalRequest?: Maybe<Scalars['String']['output']>;
  /** The category of request */
  category: Scalars['String']['output'];
  /** The reported first occurrence of the issue causing the request */
  firstOccurred?: Maybe<Scalars['String']['output']>;
  /** The editable details of the request */
  details?: Maybe<Scalars['String']['output']>;
  /** A list of hints offered and tried by the tenant to self-resolve */
  hints?: Maybe<Array<Maybe<Hint>>>;
  /** A set of extra information to ask the tenant */
  fields?: Maybe<Array<Maybe<Field>>>;
  /** A list of images part of this request */
  images?: Maybe<Array<Maybe<Image>>>;
  /** The current status of the request */
  status: Scalars['String']['output'];
  /** The current delay of the request */
  delay?: Maybe<Scalars['String']['output']>;
  /** The tenant that requested this work order */
  tenant?: Maybe<Tenant>;
  /** The contact information of the person that occupies the unit at the time the request was created */
  occupant?: Maybe<Tenant>;
  /** The duration required for the appointment */
  minutes: Scalars['Int']['output'];
  /** The appointment start date time confirmed by a manager */
  appointmentZ: Scalars['AWSDateTime']['output'];
  /** Short intructions for the operator when handling the request */
  instructions?: Maybe<Scalars['String']['output']>;
  /** Additional notes or information added into this request */
  notes?: Maybe<Array<Maybe<Note>>>;
  /** Time and date when the operator started working on this request */
  startedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Time and date when the operator stopped working on this request */
  completedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  /** The tenant's account ID at the time of the request */
  accountId: Scalars['ID']['output'];
  /** The tenant's unit ID at the time of the request */
  unitId: Scalars['ID']['output'];
  consents?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  entryConsentDetails?: Maybe<Scalars['String']['output']>;
};

export type RequestInput = {
  id: Scalars['ID']['input'];
  accountId?: InputMaybe<Scalars['ID']['input']>;
  unitId?: InputMaybe<Scalars['ID']['input']>;
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  summary: Scalars['String']['input'];
  unit: UnitInput;
  originalRequest?: InputMaybe<Scalars['String']['input']>;
  firstOccurred?: InputMaybe<Scalars['AWSDate']['input']>;
  notes?: InputMaybe<Array<InputMaybe<NoteInput>>>;
  consents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  entryConsentDetails?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  tenant?: InputMaybe<TenantInput>;
  fields?: InputMaybe<Array<InputMaybe<FieldInput>>>;
  images?: InputMaybe<Array<InputMaybe<ImageInput>>>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum RequestStatus {
  New = 'NEW',
  Started = 'STARTED',
  Submitted = 'SUBMITTED',
  Confirmed = 'CONFIRMED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
}

export type ScheduleAssignedVisitInput = {
  visitId: Scalars['ID']['input'];
  appointmentZ: Scalars['String']['input'];
};

export type ScheduleVisitInput = {
  visitId?: InputMaybe<Scalars['ID']['input']>;
  requestId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
  category?: InputMaybe<Scalars['String']['input']>;
  appointmentZ: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  minutes?: InputMaybe<Scalars['Float']['input']>;
  request?: InputMaybe<RequestInput>;
};

export type SendLoginLinkInput = {
  email: Scalars['String']['input'];
};

export type SendLoginLinkPayload = {
  __typename?: 'SendLoginLinkPayload';
  success?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

export type SetEmailCalendarOptOutInput = {
  id: Scalars['ID']['input'];
  emailCalendarOptOut?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetEmailCalendarOptOutPayload = {
  __typename?: 'SetEmailCalendarOptOutPayload';
  operator?: Maybe<Operator>;
  success?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

export type SetExpoPushTokenInput = {
  id: Scalars['ID']['input'];
  expoPushToken?: InputMaybe<Scalars['String']['input']>;
};

export type SetExpoPushTokenPayload = {
  __typename?: 'SetExpoPushTokenPayload';
  operator?: Maybe<Operator>;
};

export type SetGcalInput = {
  id_token?: InputMaybe<Scalars['String']['input']>;
  access_token?: InputMaybe<Scalars['String']['input']>;
  refresh_token?: InputMaybe<Scalars['String']['input']>;
  token_type?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  expiry_date?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['String']['input']>;
};

export type SetGcalPayload = {
  __typename?: 'SetGcalPayload';
  operator?: Maybe<Operator>;
};

export type SetLocaleInput = {
  locale: Scalars['String']['input'];
};

export type SetLocalePayload = {
  __typename?: 'SetLocalePayload';
  operator?: Maybe<Operator>;
};

export type SetVisitSeenInput = {
  visitId: Scalars['ID']['input'];
  seen: Scalars['Boolean']['input'];
};

export type StartRequestInput = {
  startedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type StartVisitInput = {
  visitId: Scalars['ID']['input'];
  startedZ: Scalars['AWSDateTime']['input'];
};

export type Tenant = {
  __typename?: 'Tenant';
  /** The system-assigned ID for the tenant */
  id?: Maybe<Scalars['ID']['output']>;
  /** The tenant's full name */
  name?: Maybe<Scalars['String']['output']>;
  /** The tenant email that initiated this request */
  email?: Maybe<Scalars['String']['output']>;
  /** The tenant's phone number at the time of the request */
  phone?: Maybe<Scalars['String']['output']>;
  /** The tenant emails */
  emails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type TenantInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Unit = {
  __typename?: 'Unit';
  /** The unit name/number */
  name: Scalars['String']['output'];
  occupancy?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Address>;
  property?: Maybe<Property>;
};

export type UnitInput = {
  name: Scalars['String']['input'];
  occupancy?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<AddressInput>;
  property?: InputMaybe<PropertyInput>;
};

export type UpdateRequestInput = {
  operatorNotes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUnreadPushNotificationsInput = {
  unreadPushNotifications: Scalars['Int']['input'];
};

export type UpdateUnreadPushNotificationsPayload = {
  __typename?: 'UpdateUnreadPushNotificationsPayload';
  operator?: Maybe<Operator>;
};

export type UpdateVisitBillInput = {
  visitId: Scalars['ID']['input'];
  date: Scalars['AWSDate']['input'];
  totalAmount: Scalars['Float']['input'];
  due?: InputMaybe<Scalars['AWSDate']['input']>;
  gstAmount?: InputMaybe<Scalars['Float']['input']>;
  ref?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVisitChecklistInput = {
  visitId: Scalars['ID']['input'];
  checklist: Array<InputMaybe<ChecklistItemInput>>;
};

export type UpdateVisitChecklistItemInput = {
  visitId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  completedZ?: InputMaybe<Scalars['AWSDateTime']['input']>;
  order: Scalars['Int']['input'];
};

export type UpdateVisitCostsInput = {
  visitId: Scalars['ID']['input'];
  materialCosts: Scalars['Float']['input'];
  materialCostsNote?: InputMaybe<Scalars['String']['input']>;
};

export type Visit = {
  __typename?: 'Visit';
  id: Scalars['ID']['output'];
  requestId: Scalars['ID']['output'];
  accountId: Scalars['ID']['output'];
  minutes: Scalars['Int']['output'];
  seen?: Maybe<Scalars['Boolean']['output']>;
  dueZ?: Maybe<Scalars['AWSDateTime']['output']>;
  appointmentZ?: Maybe<Scalars['AWSDateTime']['output']>;
  startedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  completedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  category: Scalars['String']['output'];
  status: Scalars['String']['output'];
  delay?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  request?: Maybe<VisitRequest>;
  notes?: Maybe<Array<Maybe<Note>>>;
  images?: Maybe<Array<Maybe<Image>>>;
  sentNotifications?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tenantProposedTimes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  pendingNotifications?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  materialCosts?: Maybe<Scalars['Float']['output']>;
  materialCostsNote?: Maybe<Scalars['String']['output']>;
  managerContactInformation?: Maybe<ManagerContactInformation>;
  bill?: Maybe<Bill>;
  contactInformation?: Maybe<ContactInformation>;
  /** The original visit ID if this visit is a follow-up */
  originalVisitId?: Maybe<Scalars['ID']['output']>;
  originalVisit?: Maybe<Visit>;
};

export type VisitConnection = {
  __typename?: 'VisitConnection';
  items?: Maybe<Array<Maybe<Visit>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type VisitRequest = {
  __typename?: 'VisitRequest';
  /** The system-assigned ID for the request */
  id: Scalars['ID']['output'];
  /** The tenant's account ID at the time of the request */
  accountId: Scalars['ID']['output'];
  /** The tenant's unit ID at the time of the request */
  unitId: Scalars['ID']['output'];
  /** The tenant's property ID at the time of the request */
  propertyId: Scalars['ID']['output'];
  /** The request code used for quick identification */
  name: Scalars['String']['output'];
  /** The short summary of the request */
  summary: Scalars['String']['output'];
  /** The unit this request is about */
  unit: Unit;
  /** The original message text that kicked off the request */
  originalRequest?: Maybe<Scalars['String']['output']>;
  /** The reported first occurrence of the issue causing the request */
  firstOccurred?: Maybe<Scalars['String']['output']>;
  /** Additional notes or information added into this request */
  notes?: Maybe<Array<Maybe<Note>>>;
  consents?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  entryConsentDetails?: Maybe<Scalars['String']['output']>;
  /** The editable details of the request */
  details?: Maybe<Scalars['String']['output']>;
  /** A list of images part of this request */
  images?: Maybe<Array<Maybe<Image>>>;
  /** The tenant that requested this work order */
  tenant?: Maybe<Tenant>;
  /** The other residents of the residency */
  additionalTenants?: Maybe<Array<Maybe<Tenant>>>;
  /** The contact information of the person that occupies the unit at the time the request was created */
  occupant?: Maybe<Tenant>;
  /** A set of extra information to ask the tenant */
  fields?: Maybe<Array<Maybe<Field>>>;
  /** A checklist of items */
  checklist?: Maybe<Array<Maybe<ChecklistItem>>>;
  status?: Maybe<RequestStatus>;
};

export type VisitRequiredInput = {
  requestId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
};

export type VisitRequiredPayload = {
  __typename?: 'VisitRequiredPayload';
  success?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

export enum VisitStatus {
  Started = 'STARTED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Assigned = 'ASSIGNED',
  Scheduled = 'SCHEDULED',
  Requested = 'REQUESTED',
  Declined = 'DECLINED',
}

export type VisitUpdatedPayload = {
  __typename?: 'VisitUpdatedPayload';
  visit?: Maybe<Visit>;
  success?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

export const API = {
  Query: {
    GetVisit: 'GetVisit',
  },
  Mutation: {
    AddImageWithLocation: 'AddImageWithLocation',
    AddVisitNote: 'AddVisitNote',
    DeleteVisitNote: 'DeleteVisitNote',
    CompleteVisit: 'CompleteVisit',
    DeclineVisit: 'DeclineVisit',
    VisitRequired: 'VisitRequired',
    UpdateVisitBill: 'UpdateVisitBill',
  },
  Fragment: {
    RequestFields: 'RequestFields',
    VisitFields: 'VisitFields',
    VisitRequestFields: 'VisitRequestFields',
  },
};
export type AddImageWithLocationMutationVariables = Exact<{
  input: AddImageWithLocationInput;
}>;

export type AddImageWithLocationMutation = {
  __typename?: 'Mutation';
  addImageWithLocation: {
    __typename?: 'AddImageWithLocationPayload';
    error?: Maybe<string>;
    success?: Maybe<boolean>;
    request?: Maybe<{ __typename?: 'Request' } & RequestFieldsFragment>;
    visit?: Maybe<{ __typename?: 'Visit' } & VisitFieldsFragment>;
  };
};

export type RequestFieldsFragment = {
  __typename?: 'Request';
  id: string;
  accountId: string;
  name: string;
  category: string;
  appointmentZ: string;
  startedZ?: Maybe<string>;
  completedZ?: Maybe<string>;
  minutes: number;
  details?: Maybe<string>;
  originalRequest?: Maybe<string>;
  instructions?: Maybe<string>;
  status: string;
  delay?: Maybe<string>;
  summary: string;
  firstOccurred?: Maybe<string>;
  consents?: Maybe<Array<Maybe<string>>>;
  entryConsentDetails?: Maybe<string>;
  notes?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Note';
        id: string;
        text?: Maybe<string>;
        source?: Maybe<string>;
        target?: Maybe<string>;
        createdZ?: Maybe<string>;
      }>
    >
  >;
  fields?: Maybe<
    Array<Maybe<{ __typename?: 'Field'; label?: Maybe<string>; value?: Maybe<string> }>>
  >;
  images?: Maybe<Array<Maybe<{ __typename?: 'Image'; key?: Maybe<string> }>>>;
  tenant?: Maybe<{
    __typename?: 'Tenant';
    name?: Maybe<string>;
    phone?: Maybe<string>;
    email?: Maybe<string>;
  }>;
  unit: {
    __typename?: 'Unit';
    name: string;
    occupancy?: Maybe<string>;
    property?: Maybe<{
      __typename?: 'Property';
      key?: Maybe<string>;
      phone?: Maybe<string>;
      account?: Maybe<{ __typename?: 'PropertyAccount'; phone?: Maybe<string> }>;
      address?: Maybe<{
        __typename?: 'Address';
        street?: Maybe<string>;
        city?: Maybe<string>;
        province?: Maybe<string>;
        postal?: Maybe<string>;
      }>;
    }>;
  };
};

export type GetVisitQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetVisitQuery = {
  __typename?: 'Query';
  visit?: Maybe<{ __typename?: 'Visit' } & VisitFieldsFragment>;
};

export type AddVisitNoteMutationVariables = Exact<{
  input: AddVisitNoteInput;
}>;

export type AddVisitNoteMutation = {
  __typename?: 'Mutation';
  addVisitNote?: Maybe<{
    __typename?: 'VisitUpdatedPayload';
    success?: Maybe<boolean>;
    visit?: Maybe<{
      __typename?: 'Visit';
      id: string;
      notes?: Maybe<
        Array<
          Maybe<{
            __typename?: 'Note';
            id: string;
            text?: Maybe<string>;
            source?: Maybe<string>;
            target?: Maybe<string>;
            createdZ?: Maybe<string>;
          }>
        >
      >;
    }>;
  }>;
};

export type DeleteVisitNoteMutationVariables = Exact<{
  input: DeleteVisitNoteInput;
}>;

export type DeleteVisitNoteMutation = {
  __typename?: 'Mutation';
  deleteVisitNote?: Maybe<{
    __typename?: 'VisitUpdatedPayload';
    success?: Maybe<boolean>;
    visit?: Maybe<{
      __typename?: 'Visit';
      id: string;
      notes?: Maybe<
        Array<
          Maybe<{
            __typename?: 'Note';
            id: string;
            text?: Maybe<string>;
            source?: Maybe<string>;
            target?: Maybe<string>;
            createdZ?: Maybe<string>;
          }>
        >
      >;
    }>;
  }>;
};

export type CompleteVisitMutationVariables = Exact<{
  input: CompleteVisitInput;
}>;

export type CompleteVisitMutation = {
  __typename?: 'Mutation';
  completeVisit?: Maybe<{
    __typename?: 'VisitUpdatedPayload';
    success?: Maybe<boolean>;
    error?: Maybe<string>;
    visit?: Maybe<{ __typename?: 'Visit' } & VisitFieldsFragment>;
  }>;
};

export type DeclineVisitMutationVariables = Exact<{
  input: DeclineVisitInput;
}>;

export type DeclineVisitMutation = {
  __typename?: 'Mutation';
  declineVisit?: Maybe<{
    __typename?: 'VisitUpdatedPayload';
    success?: Maybe<boolean>;
    error?: Maybe<string>;
    visit?: Maybe<{ __typename?: 'Visit' } & VisitFieldsFragment>;
  }>;
};

export type VisitFieldsFragment = {
  __typename?: 'Visit';
  id: string;
  requestId: string;
  accountId: string;
  minutes: number;
  dueZ?: Maybe<string>;
  seen?: Maybe<boolean>;
  appointmentZ?: Maybe<string>;
  startedZ?: Maybe<string>;
  completedZ?: Maybe<string>;
  category: string;
  delay?: Maybe<string>;
  status: string;
  timezone?: Maybe<string>;
  instructions?: Maybe<string>;
  notes?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Note';
        id: string;
        text?: Maybe<string>;
        source?: Maybe<string>;
        target?: Maybe<string>;
        createdZ?: Maybe<string>;
      }>
    >
  >;
  images?: Maybe<Array<Maybe<{ __typename?: 'Image'; key?: Maybe<string> }>>>;
  request?: Maybe<{ __typename?: 'VisitRequest' } & VisitRequestFieldsFragment>;
  bill?: Maybe<{
    __typename?: 'Bill';
    date: string;
    due?: Maybe<string>;
    ref?: Maybe<string>;
    key?: Maybe<string>;
    totalAmount: number;
    gstAmount?: Maybe<number>;
  }>;
};

export type VisitRequestFieldsFragment = {
  __typename?: 'VisitRequest';
  name: string;
  details?: Maybe<string>;
  originalRequest?: Maybe<string>;
  summary: string;
  firstOccurred?: Maybe<string>;
  consents?: Maybe<Array<Maybe<string>>>;
  entryConsentDetails?: Maybe<string>;
  notes?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Note';
        id: string;
        text?: Maybe<string>;
        source?: Maybe<string>;
        target?: Maybe<string>;
        createdZ?: Maybe<string>;
      }>
    >
  >;
  fields?: Maybe<
    Array<Maybe<{ __typename?: 'Field'; label?: Maybe<string>; value?: Maybe<string> }>>
  >;
  images?: Maybe<Array<Maybe<{ __typename?: 'Image'; key?: Maybe<string> }>>>;
  tenant?: Maybe<{
    __typename?: 'Tenant';
    name?: Maybe<string>;
    phone?: Maybe<string>;
    email?: Maybe<string>;
    emails?: Maybe<Array<Maybe<string>>>;
  }>;
  additionalTenants?: Maybe<
    Array<
      Maybe<{
        __typename?: 'Tenant';
        name?: Maybe<string>;
        phone?: Maybe<string>;
        email?: Maybe<string>;
        emails?: Maybe<Array<Maybe<string>>>;
      }>
    >
  >;
  unit: {
    __typename?: 'Unit';
    name: string;
    occupancy?: Maybe<string>;
    property?: Maybe<{
      __typename?: 'Property';
      key?: Maybe<string>;
      phone?: Maybe<string>;
      account?: Maybe<{ __typename?: 'PropertyAccount'; phone?: Maybe<string> }>;
      address?: Maybe<{
        __typename?: 'Address';
        street?: Maybe<string>;
        city?: Maybe<string>;
        province?: Maybe<string>;
        postal?: Maybe<string>;
      }>;
    }>;
  };
};

export type VisitRequiredMutationVariables = Exact<{
  input: VisitRequiredInput;
}>;

export type VisitRequiredMutation = {
  __typename?: 'Mutation';
  visitRequired?: Maybe<{
    __typename?: 'VisitRequiredPayload';
    success?: Maybe<boolean>;
    error?: Maybe<string>;
  }>;
};

export type UpdateVisitBillMutationVariables = Exact<{
  input: UpdateVisitBillInput;
}>;

export type UpdateVisitBillMutation = {
  __typename?: 'Mutation';
  updateVisitBill?: Maybe<{
    __typename?: 'VisitUpdatedPayload';
    success?: Maybe<boolean>;
    error?: Maybe<string>;
    visit?: Maybe<{ __typename?: 'Visit' } & VisitFieldsFragment>;
  }>;
};

export const RequestFieldsFragmentDoc = gql`
  fragment RequestFields on Request {
    id
    accountId
    name
    category
    appointmentZ
    startedZ
    completedZ
    minutes
    details
    originalRequest
    instructions
    status
    delay
    summary
    firstOccurred
    consents
    notes {
      id
      text
      source
      target
      createdZ
    }
    entryConsentDetails
    fields {
      label
      value
    }
    images {
      key
    }
    tenant {
      name
      phone
      email
    }
    unit {
      name
      occupancy
      property {
        key
        phone
        account {
          phone
        }
        address {
          street
          city
          province
          postal
        }
      }
    }
  }
`;
export const VisitRequestFieldsFragmentDoc = gql`
  fragment VisitRequestFields on VisitRequest {
    name
    details
    originalRequest
    summary
    firstOccurred
    consents
    entryConsentDetails
    notes {
      id
      text
      source
      target
      createdZ
    }
    fields {
      label
      value
    }
    images {
      key
    }
    tenant {
      name
      phone
      email
      emails
    }
    additionalTenants {
      name
      phone
      email
      emails
    }
    unit {
      name
      occupancy
      property {
        key
        phone
        account {
          phone
        }
        address {
          street
          city
          province
          postal
        }
      }
    }
  }
`;
export const VisitFieldsFragmentDoc = gql`
  fragment VisitFields on Visit {
    id
    requestId
    accountId
    minutes
    dueZ
    seen
    appointmentZ
    startedZ
    completedZ
    category
    delay
    status
    timezone
    instructions
    notes {
      id
      text
      source
      target
      createdZ
    }
    images {
      key
    }
    request {
      ...VisitRequestFields
    }
    bill {
      date
      due
      ref
      key
      totalAmount
      gstAmount
    }
  }
  ${VisitRequestFieldsFragmentDoc}
`;
export const AddImageWithLocationDocument = gql`
  mutation AddImageWithLocation($input: AddImageWithLocationInput!) {
    addImageWithLocation(input: $input) {
      error
      success
      request {
        ...RequestFields
      }
      visit {
        ...VisitFields
      }
    }
  }
  ${RequestFieldsFragmentDoc}
  ${VisitFieldsFragmentDoc}
`;
export type AddImageWithLocationMutationFn = Apollo.MutationFunction<
  AddImageWithLocationMutation,
  AddImageWithLocationMutationVariables
>;

/**
 * __useAddImageWithLocationMutation__
 *
 * To run a mutation, you first call `useAddImageWithLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddImageWithLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addImageWithLocationMutation, { data, loading, error }] = useAddImageWithLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddImageWithLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddImageWithLocationMutation,
    AddImageWithLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddImageWithLocationMutation, AddImageWithLocationMutationVariables>(
    AddImageWithLocationDocument,
    options
  );
}
export type AddImageWithLocationMutationHookResult = ReturnType<
  typeof useAddImageWithLocationMutation
>;
export type AddImageWithLocationMutationResult =
  Apollo.MutationResult<AddImageWithLocationMutation>;
export type AddImageWithLocationMutationOptions = Apollo.BaseMutationOptions<
  AddImageWithLocationMutation,
  AddImageWithLocationMutationVariables
>;
export const GetVisitDocument = gql`
  query GetVisit($id: ID!) {
    visit(id: $id) {
      ...VisitFields
    }
  }
  ${VisitFieldsFragmentDoc}
`;

/**
 * __useGetVisitQuery__
 *
 * To run a query within a React component, call `useGetVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVisitQuery(
  baseOptions: Apollo.QueryHookOptions<GetVisitQuery, GetVisitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVisitQuery, GetVisitQueryVariables>(GetVisitDocument, options);
}
export function useGetVisitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVisitQuery, GetVisitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVisitQuery, GetVisitQueryVariables>(GetVisitDocument, options);
}
export type GetVisitQueryHookResult = ReturnType<typeof useGetVisitQuery>;
export type GetVisitLazyQueryHookResult = ReturnType<typeof useGetVisitLazyQuery>;
export type GetVisitQueryResult = Apollo.QueryResult<GetVisitQuery, GetVisitQueryVariables>;
export const AddVisitNoteDocument = gql`
  mutation AddVisitNote($input: AddVisitNoteInput!) {
    addVisitNote(input: $input) {
      success
      visit {
        id
        notes {
          id
          text
          source
          target
          createdZ
        }
      }
    }
  }
`;
export type AddVisitNoteMutationFn = Apollo.MutationFunction<
  AddVisitNoteMutation,
  AddVisitNoteMutationVariables
>;

/**
 * __useAddVisitNoteMutation__
 *
 * To run a mutation, you first call `useAddVisitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVisitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVisitNoteMutation, { data, loading, error }] = useAddVisitNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVisitNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<AddVisitNoteMutation, AddVisitNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddVisitNoteMutation, AddVisitNoteMutationVariables>(
    AddVisitNoteDocument,
    options
  );
}
export type AddVisitNoteMutationHookResult = ReturnType<typeof useAddVisitNoteMutation>;
export type AddVisitNoteMutationResult = Apollo.MutationResult<AddVisitNoteMutation>;
export type AddVisitNoteMutationOptions = Apollo.BaseMutationOptions<
  AddVisitNoteMutation,
  AddVisitNoteMutationVariables
>;
export const DeleteVisitNoteDocument = gql`
  mutation DeleteVisitNote($input: DeleteVisitNoteInput!) {
    deleteVisitNote(input: $input) {
      success
      visit {
        id
        notes {
          id
          text
          source
          target
          createdZ
        }
      }
    }
  }
`;
export type DeleteVisitNoteMutationFn = Apollo.MutationFunction<
  DeleteVisitNoteMutation,
  DeleteVisitNoteMutationVariables
>;

/**
 * __useDeleteVisitNoteMutation__
 *
 * To run a mutation, you first call `useDeleteVisitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVisitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVisitNoteMutation, { data, loading, error }] = useDeleteVisitNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVisitNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVisitNoteMutation,
    DeleteVisitNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVisitNoteMutation, DeleteVisitNoteMutationVariables>(
    DeleteVisitNoteDocument,
    options
  );
}
export type DeleteVisitNoteMutationHookResult = ReturnType<typeof useDeleteVisitNoteMutation>;
export type DeleteVisitNoteMutationResult = Apollo.MutationResult<DeleteVisitNoteMutation>;
export type DeleteVisitNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteVisitNoteMutation,
  DeleteVisitNoteMutationVariables
>;
export const CompleteVisitDocument = gql`
  mutation CompleteVisit($input: CompleteVisitInput!) {
    completeVisit(input: $input) {
      success
      error
      visit {
        ...VisitFields
      }
    }
  }
  ${VisitFieldsFragmentDoc}
`;
export type CompleteVisitMutationFn = Apollo.MutationFunction<
  CompleteVisitMutation,
  CompleteVisitMutationVariables
>;

/**
 * __useCompleteVisitMutation__
 *
 * To run a mutation, you first call `useCompleteVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeVisitMutation, { data, loading, error }] = useCompleteVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteVisitMutation, CompleteVisitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteVisitMutation, CompleteVisitMutationVariables>(
    CompleteVisitDocument,
    options
  );
}
export type CompleteVisitMutationHookResult = ReturnType<typeof useCompleteVisitMutation>;
export type CompleteVisitMutationResult = Apollo.MutationResult<CompleteVisitMutation>;
export type CompleteVisitMutationOptions = Apollo.BaseMutationOptions<
  CompleteVisitMutation,
  CompleteVisitMutationVariables
>;
export const DeclineVisitDocument = gql`
  mutation DeclineVisit($input: DeclineVisitInput!) {
    declineVisit(input: $input) {
      success
      error
      visit {
        ...VisitFields
      }
    }
  }
  ${VisitFieldsFragmentDoc}
`;
export type DeclineVisitMutationFn = Apollo.MutationFunction<
  DeclineVisitMutation,
  DeclineVisitMutationVariables
>;

/**
 * __useDeclineVisitMutation__
 *
 * To run a mutation, you first call `useDeclineVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineVisitMutation, { data, loading, error }] = useDeclineVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<DeclineVisitMutation, DeclineVisitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeclineVisitMutation, DeclineVisitMutationVariables>(
    DeclineVisitDocument,
    options
  );
}
export type DeclineVisitMutationHookResult = ReturnType<typeof useDeclineVisitMutation>;
export type DeclineVisitMutationResult = Apollo.MutationResult<DeclineVisitMutation>;
export type DeclineVisitMutationOptions = Apollo.BaseMutationOptions<
  DeclineVisitMutation,
  DeclineVisitMutationVariables
>;
export const VisitRequiredDocument = gql`
  mutation VisitRequired($input: VisitRequiredInput!) {
    visitRequired(input: $input) {
      success
      error
    }
  }
`;
export type VisitRequiredMutationFn = Apollo.MutationFunction<
  VisitRequiredMutation,
  VisitRequiredMutationVariables
>;

/**
 * __useVisitRequiredMutation__
 *
 * To run a mutation, you first call `useVisitRequiredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitRequiredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitRequiredMutation, { data, loading, error }] = useVisitRequiredMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVisitRequiredMutation(
  baseOptions?: Apollo.MutationHookOptions<VisitRequiredMutation, VisitRequiredMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VisitRequiredMutation, VisitRequiredMutationVariables>(
    VisitRequiredDocument,
    options
  );
}
export type VisitRequiredMutationHookResult = ReturnType<typeof useVisitRequiredMutation>;
export type VisitRequiredMutationResult = Apollo.MutationResult<VisitRequiredMutation>;
export type VisitRequiredMutationOptions = Apollo.BaseMutationOptions<
  VisitRequiredMutation,
  VisitRequiredMutationVariables
>;
export const UpdateVisitBillDocument = gql`
  mutation UpdateVisitBill($input: UpdateVisitBillInput!) {
    updateVisitBill(input: $input) {
      success
      error
      visit {
        ...VisitFields
      }
    }
  }
  ${VisitFieldsFragmentDoc}
`;
export type UpdateVisitBillMutationFn = Apollo.MutationFunction<
  UpdateVisitBillMutation,
  UpdateVisitBillMutationVariables
>;

/**
 * __useUpdateVisitBillMutation__
 *
 * To run a mutation, you first call `useUpdateVisitBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisitBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisitBillMutation, { data, loading, error }] = useUpdateVisitBillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVisitBillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVisitBillMutation,
    UpdateVisitBillMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVisitBillMutation, UpdateVisitBillMutationVariables>(
    UpdateVisitBillDocument,
    options
  );
}
export type UpdateVisitBillMutationHookResult = ReturnType<typeof useUpdateVisitBillMutation>;
export type UpdateVisitBillMutationResult = Apollo.MutationResult<UpdateVisitBillMutation>;
export type UpdateVisitBillMutationOptions = Apollo.BaseMutationOptions<
  UpdateVisitBillMutation,
  UpdateVisitBillMutationVariables
>;
