import { Card, TitleWithSubtitle } from 'components';
import { VisitRequestFieldsFragment } from 'api';
import { CONSENTS } from 'system';

export default function VisitDetailsCard({
  originalRequest = '',
  details,
  firstOccurred,
  entryConsentDetails,
  consents,
  hasTenant,
}: {
  originalRequest?: VisitRequestFieldsFragment['originalRequest'];
  details?: VisitRequestFieldsFragment['details'];
  firstOccurred?: VisitRequestFieldsFragment['firstOccurred'];
  entryConsentDetails?: VisitRequestFieldsFragment['entryConsentDetails'];
  consents: VisitRequestFieldsFragment['consents'];
  hasTenant: boolean;
}) {
  const hasDetails = Boolean(details);
  const hasConsents = Boolean(consents && consents.length >= 0);
  const hasEntryConsentDetails = Boolean(entryConsentDetails);

  return (
    <Card>
      <TitleWithSubtitle title="Issue description:" subtitle={originalRequest} />
      {hasDetails && <TitleWithSubtitle title="Additional details:" subtitle={details ?? ''} />}
      {firstOccurred && (
        <TitleWithSubtitle title="Issue first occurred:" subtitle={firstOccurred} />
      )}
      {hasTenant &&
        hasConsents &&
        CONSENTS.map((consent) => {
          const visitHasConsent = consents?.includes(consent.value);
          return (
            <TitleWithSubtitle
              key={consent.value}
              title={`Consent to ${consent.label}:`}
              subtitle={visitHasConsent ? 'Yes' : 'No'}
            />
          );
        })}
      {hasEntryConsentDetails && (
        <TitleWithSubtitle
          title="Additional details for technician to enter:"
          subtitle={entryConsentDetails ?? ''}
        />
      )}
    </Card>
  );
}
