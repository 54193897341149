import { VisitFieldsFragment } from 'api';
import { useNotification } from 'hooks';
import { DateTime } from 'luxon';

enum Notifications {
  emailCopied = 'Email copied to clipboard',
  phoneCopied = 'Phone copied to clipboard',
}

export const useVisitPresenter = ({ visit }: { visit: VisitFieldsFragment }) => {
  const { sendNotification } = useNotification();
  const date = DateTime.fromISO(visit?.dueZ ?? '');
  const dueDate = date.setZone(visit?.timezone ?? '').toLocaleString(DateTime.DATETIME_MED);
  const tenant = visit.request?.tenant;
  const copyText = async (text: string) => navigator.clipboard.writeText(text);
  const copyEmail = (email: string) => {
    void copyText(email);
    sendNotification(Notifications.emailCopied, 'success');
  };
  const copyPhone = () => {
    void copyText(tenant?.phone ?? '');
    sendNotification(Notifications.phoneCopied, 'success');
  };
  const { occupancy, name: unitName } = visit?.request?.unit ?? {};
  return {
    dueDate,
    tenant,
    copyEmail,
    copyPhone,
    occupancy,
    unitName,
  };
};
